.body-tables{
  padding: 5rem 3rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 100px 50px;
  place-items: center;
}

.mesa-type-1{
  position: relative;
  height: 80px;
  width: 220px;

  .table{
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    background-color: #bb8a3c;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3f1d05;
  }
  
  .silla-1, .silla-2{
    position: absolute;
    margin-top: -35px;
    height: 50px;
    width: 60px;
    background-color: #36d800;
    border-radius: 15px;
    z-index: 3;
    cursor: pointer;
    transition: .3s all;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .silla-1:hover, .silla-2:hover{
    margin-top: -30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  }
  
  .silla-1{
    left: 30px;
  }
  
  .silla-2{
    right: 30px;
  }
}

.mesa-type-1-1{
  position: relative;
  height: 80px;
  width: 220px;

  .table{
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
    background-color: #c49952;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #53280a;
  }
  
  .silla-1, .silla-2{
    position: absolute;
    margin-top: -35px;
    height: 50px;
    width: 60px;
    background-color: #36d800;
    border-radius: 15px;
    z-index: 3;
    cursor: pointer;
    transition: .3s all;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .silla-1:hover, .silla-2:hover{
    margin-top: -40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  }
  
  .silla-1{
    left: 30px;
    top: 0px;
  }
  
  .silla-2{
    right: 30px;
    top: 0px;
  }
}

.mesa-type-2{
  position: relative;
  height: 80px;
  width: 220px;
  
  .table{
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
    background-color: #c49952;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #53280a;
  }
  
  .silla-1, .silla-2, .silla-3, .silla-4{
    position: absolute;
    margin-top: -35px;
    height: 50px;
    width: 60px;
    background-color: #36d800;
    border-radius: 15px;
    z-index: 3;
    cursor: pointer;
    transition: .3s all;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .silla-1:hover, .silla-2:hover{
    margin-top: -30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  }
  
  .silla-3:hover, .silla-4:hover{
    margin-top: -40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  }
  
  .silla-1{
    left: 30px;
  }
  
  .silla-2{
    right: 30px;
  }
  
  .silla-3{
    left: 30px;
    top: 0px;
  }
  
  .silla-4{
    right: 30px;
    top: 0px;
  }
}

.mesa-type-3{
  position: relative;
  height: 80px;
  width: 220px;
  
  .table{
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
    background-color: #c49952;
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #53280a;
  }
  
  .silla-1, .silla-2, .silla-3, .silla-4, .silla-5, .silla-6{
    position: absolute;
    margin-top: -35px;
    height: 50px;
    width: 60px;
    background-color: #36d800;
    border-radius: 15px;
    z-index: 3;
    cursor: pointer;
    transition: .3s all;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  
  .silla-1:hover, .silla-2:hover{
    margin-top: -30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  }
  
  .silla-3:hover, .silla-4:hover{
    margin-top: -40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  }
  
  .silla-5:hover{
    margin-left: -5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  }
  
  .silla-6:hover{
    margin-right: -5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  }
  
  .silla-1{
    left: 30px;
  }
  
  .silla-2{
    right: 30px;
  }
  
  .silla-3{
    left: 30px;
    top: 0px;
  }
  
  .silla-4{
    right: 30px;
    top: 0px;
  }
  
  .silla-5{
    left: -25px;
    top: 50px;
  }
  
  .silla-6{
    right: -25px;
    top: 50px;
  }
}

.btn-add-table{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: none;
  margin-left: 10px;
  margin-bottom: 5px;
  color: #000;
  transition: .3s all;
  cursor: pointer;

  &:hover{
    background-color: #c0c0c0;
    color: #000;
  }
}

.body-create-table{
  background-color: #ccb4a2;
  border-radius: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px 35px;
  padding: 3rem;
  justify-content: center;
  align-items: center;
}

.bg-grey{
  background-color: grey !important;
}

.bg-status-1{
  background-color: rgb(109, 57, 57) !important;
}

.bg-status-2{
  background-color: #6b5727 !important;
}