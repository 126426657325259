@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

:root {
  --tf-color: #01b6d8;
}

.app-overflow-none{
  overflow: hidden;
}

*{
  font-family: 'Open Sans', sans-serif;
}

span, a, td {
  font-weight: 400 !important;
}

h3, h4 {
  font-weight: 600 !important;
}

.MuiTableCell-root{
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 400 !important;
}


.box {
  height: 80px;
  color: white;
  cursor: grab;
  background-color: gray;    
  box-shadow: 0px 0px 10px 1px #000;
  display: inline-block;
  margin: 5px;
}

.nav-button{
  background-color: black;
  color: white;
  padding: 10px;
  border: 0;
  min-width: 70px;
  border-right: 1px solid var(--tf-color);
}

.nav-button:hover {
  color: white;
  background-color: var(--tf-color);
}

.nav-button:active{
  color: white;
  background-color: var(--tf-color);
}

.nav-log-out{
  background-color: #fb233b;
  color: white;
  padding: 10px;
  border: 0;
  min-width: 70px;
}

.nav-log-out:hover {
  color: white;
  background-color: #cf172b;
}

.nav-log-out:active{
  color: white;
  background-color: #cf172b;
}

.App {
  text-align: center;
}

.row-container{
  height: 97% !important;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--tf-color);
}

.app-container {
  padding-top: 35px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 10px;
  height: 100vh !important;
  width: 100%; 
}

.main-container{
  height: calc(100% - 4rem);
  background-color: rgba(8, 8, 8, 0.600);
  backdrop-filter: blur(12px);
}

.products-content::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);background-color: #F5F5F5;border-radius: 1rem;}
.products-content::-webkit-scrollbar{width: 8px;height: 0px;background-color: #F5F5F5;border-radius: 1rem;}
.products-content::-webkit-scrollbar-thumb{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);background-color: rgb(28, 28, 28); border-radius: .5rem;}

.card-product{
  height: 120px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.631) !important;
  transition: .3s;
}

.card-product:hover{
  box-shadow: 0 0.155rem 0.5rem rgba(187, 187, 187, 0.631) !important;
}

.card-product-in-order{
  height: 120px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.125rem 0.25rem rgba(1, 182, 216, 0.631) !important;
  transition: .3s;
}

.card-product-in-order:hover{
  height: 120px !important;
  box-shadow: 0 0.155rem 0.5rem rgba(0, 217, 255, 0.631) !important;
}

.container-img-product{
  height: 100px !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.container-img-product-form{
  height: 56px !important;
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.name-product{
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  height: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price-product{
  height: 30px;
}

.cursor-pointer{
  cursor: pointer;
}

.bg-glass{
  background-color: rgba(8, 8, 8, 0.227) !important;
  backdrop-filter: blur(0.1px) !important;
}

.rounded-left{
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}

.rounded-right{
  border-bottom-right-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-top{
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.rounded-bottom{
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.rounded{
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.vertical-center{
  display: -webkit-flex;
  display: flex;
  align-items: center;
}

.h-70{
  height: 70vh !important;
}

.h-80{
  height: 80vh !important;
}

/* MESAS */

/* Rojo (Ordenado) */
  .bg-mesa-taking-order{
    border-radius:0.5rem;
    color: #fff;
    background-color: #f40000 !important;
    border: 1px solid #f40000 !important;
  }

/* Amarillo (Está en proceso de entrega) */
  .bg-mesa-preparing-order{
    border-radius:0.5rem;
    color:#fff;
    background-color: #ffb300 !important;
    border: 1px solid #ffb300 !important;
  }

/* Verde (Ya pago) */
  .bg-mesa-pay-order{
    border-radius:0.5rem;
    color:#fff;
    background-color: #36d800 !important;
    border: 1px solid #36d800 !important;
  }

/* Gris (Sin asignar) */
  .bg-mesa-without-order{
    border-radius:0.5rem;
    color:#fff;
    background-color: #6c7279 !important;
    border: 1px solid #6c7279 !important;
  }


/* Carrousel */
  .btn-back-item, .btn-next-item, .btn-filter-product{
    border: 1px solid #FFF !important; 
    background-color: #000 !important;
    z-index: 999 !important;
    top: 0.2rem;
    border-radius: 5rem !important;
  }
  
  .btn-filter-product:hover{
    background-color: var(--tf-color) !important;    
  }

  .btn-filter-product-active{
    background-color: var(--tf-color) !important;    
  }

  .btn-next-item:hover{
    background-color: var(--tf-color) !important;
  }

  .btn-back-item:hover{
    background-color: var(--tf-color) !important;
  }

  .carousel-horozontal {
    overflow-x: scroll !important;
    white-space: nowrap;
    scroll-behavior: smooth;
  }
  
  .carousel-horozontal::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);border-radius: 10px;background-color: #F5F5F5;}
  .carousel-horozontal::-webkit-scrollbar{width: 0px;height: 0px;background-color: #F5F5F5;}
  .carousel-horozontal::-webkit-scrollbar-thumb{border-radius: 10px;-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);background-color: #555;}

  .main-container::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);border-radius: 10px;background-color: #F5F5F5;}
  .main-container::-webkit-scrollbar{width: 0px;height: 0px;background-color: #F5F5F5;}
  .main-container::-webkit-scrollbar-thumb{border-radius: 10px;-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);background-color: #555;}
  
.z-index-9{
  z-index: 999 !important;
}

.factura-container{
  position: relative;
}

.buttons-factura-container{
  bottom: 0;
}

.product-list-factura-container{
  height: calc(100% - 140px);
}

.col-delete-product{
  width: 60px;
}

.col-product-product-list{
  width: 198px;
}

.col-amount-product-list{
  width: 90px;
}

.col-price-product-list{
  width: 90px;
}

.bg-glass-light{
  background-color: rgb(255, 255, 255) !important;
  backdrop-filter: blur(0.1px) !important;
}

.border-bottom-table{
  border-bottom: 1px solid #000;
}

.border-top-table{
  border-top: 1px solid #000;
}

.col-label-total-product-list{
  width: 288px;
}

.col-total-price-product-list{
  width: 90px;
}

.product-list-table{
  min-height: 88%!important ;
  height: 88%!important;
}

.backdrop{
  background-color: #00000082;
  position: absolute;
  z-index: 1000;
  bottom: 0;
  top: -98px;
  left: -50px;
  right: 0;
}

.modal{
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  z-index: 1001; 
}

.modal-detail-product{
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  z-index: 1001; 
}

.rounded-pill-primary{
  border-radius: 50rem!important;
  color: var(--tf-color) !important;
  border-color: var(--tf-color) !important;
}

.nav-bar-active{
  background-color: var(--tf-color) !important;
}

.MuiTableCell-root{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.MuiPaper-elevation2{
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
}

.nav-link{
  border-radius: .25rem;
}

.nav-link-collapsible{
  border-radius: .25rem;
  display: block;
  padding: .5rem 1rem;
  text-decoration: none;
}

.h-100-i{
  height: 100vh;
}

.vl {
  border-left: 2px solid rgb(255, 255, 255);
  height: 50vh;
}

.bg-security{
  background: linear-gradient(-45deg, #151931, #142856, #106a8b, #00b5d1);
	background-size: 100% 100%;
}

.btn-logout{
  float: right;
  top: -10px;
  position: relative;
}

.modal-priority{
  overflow: auto;
  height: 300px;
}

.draggable-item{
  height: 50px;
  line-height: 50px;
  text-align: center;
  display: block;
  background-color: #fff;
  outline: 0;
  border: 1px solid rgba(0,0,0,.125);
  margin-bottom: 2px;
  margin-top: 2px;
  cursor: grab;
}

.detail-content{
  overflow: scroll;
  height: 100%;
  border-radius: 5px !important;
}

.detail-content::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);border-radius: 10px;background-color: #F5F5F5;}
.detail-content::-webkit-scrollbar{width: 0px;height: 0px;background-color: #F5F5F5;}
.detail-content::-webkit-scrollbar-thumb{border-radius: 10px;-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);background-color: #555;}

.inside-scroll-container{
  overflow: scroll;
}

.inside-scroll-container::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);border-radius: 10px;background-color: #F5F5F5;}
.inside-scroll-container::-webkit-scrollbar{width: 0px;height: 0px;background-color: #F5F5F5;}
.inside-scroll-container::-webkit-scrollbar-thumb{border-radius: 10px;-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);background-color: #555;}

.menu-content{
  overflow: scroll;
  height: 97%;
}

.menu-content::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);border-radius: 10px;background-color: #F5F5F5;}
.menu-content::-webkit-scrollbar{width: 0px;height: 0px;background-color: #F5F5F5;}
.menu-content::-webkit-scrollbar-thumb{border-radius: 10px;-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);background-color: #555;}

#containerElement{
  height: 67.6vh;
}

#containerElement::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);border-radius: 10px;background-color: #F5F5F5;}
#containerElement::-webkit-scrollbar{width: 0px;height: 0px;background-color: #F5F5F5;}
#containerElement::-webkit-scrollbar-thumb{border-radius: 10px;-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);background-color: #555;}

/************************************************************************/
/******************************* 404 ************************************/
/************************************************************************/

@-moz-keyframes rocket-movement { 100% {-moz-transform: translate(800px,-600px);} }
@-webkit-keyframes rocket-movement {100% {-webkit-transform: translate(800px,-600px); } }
@keyframes rocket-movement { 100% {transform: translate(800px,-600px);} }

@-moz-keyframes spin-earth { 100% { -moz-transform: rotate(-360deg); transition: transform 20s;  } }
@-webkit-keyframes spin-earth { 100% { -webkit-transform: rotate(-360deg); transition: transform 20s;  } }
@keyframes spin-earth{ 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); transition: transform 20s; } }

@-moz-keyframes move-astronaut {
    100% { -moz-transform: translate(-160px, -160px);}
}
@-webkit-keyframes move-astronaut {
    100% { -webkit-transform: translate(-160px, -160px);}
}
@keyframes move-astronaut{
    100% { -webkit-transform: translate(-160px, -160px); transform:translate(-160px, -160px); }
}
@-moz-keyframes rotate-astronaut {
    100% { -moz-transform: rotate(-720deg);}
}
@-webkit-keyframes rotate-astronaut {
    100% { -webkit-transform: rotate(-720deg);}
}
@keyframes rotate-astronaut{
    100% { -webkit-transform: rotate(-720deg); transform:rotate(-720deg); }
}

.objects img{
    z-index: 90;
    pointer-events: none;
}

.object_rocket{
    z-index: 95;
    position: absolute;
    transform: translateX(-80px);
    top: 80%;
    left: 30%;
    pointer-events: none;
    animation: rocket-movement 300s linear infinite both running;
}

.object_earth{
    position: absolute;
    top: 40%;
    left: 15%;
    z-index: 90;
}

.object_moon{
    position: absolute;
    top: 30%;
    left: 30%;
}

.object_astronaut{
    animation: rotate-astronaut 200s infinite linear both alternate;
}

.box_astronaut{
    z-index: 110 !important;
    position: absolute;
    top: 60%;
    right: 15%;
    will-change: transform;
    animation: move-astronaut 50s infinite linear both alternate;
}

.button-404 {
  font-size: 125px;
  color: rgba(255,255,255,1);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-shadow: 0 0 10px rgba(255,255,255,1) , 0 0 20px rgba(255,255,255,1) , 0 0 30px rgba(255,255,255,1) , 0 0 40px #00ffff , 0 0 70px #00ffff , 0 0 80px #00ffff , 0 0 100px #00ffff ;
  -webkit-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -moz-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  -o-transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
  transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
}

.button-404:hover {
  text-shadow: 0 0 10px rgba(255,255,255,1) , 0 0 20px rgba(255,255,255,1) , 0 0 30px rgba(255,255,255,1) , 0 0 40px #ff00de , 0 0 70px #ff00de , 0 0 80px #ff00de , 0 0 100px #ff00de ;
}

.button-back-404{
  padding: 0.5rem 1rem;
  border: 1px solid #00ffff;
  border-radius: 0.75rem;
  color: #fff;
  cursor: pointer;
  transition: all 200ms cubic-bezier(0.42, 0, 0.58, 1);
}

.button-back-404:hover{
  border: 1px solid #ff00de;
}

ol {
  margin:0 0 1.5em;
  counter-reset:item;
}
 
ol>li {
  margin:0;
  padding:0 0 0 2em;
  text-indent:-2em;
  list-style-type:none;
  counter-increment:item;
}
 
ol>li:before {
  display:inline-block;
  width:0.5em;
  padding-right:0.5em;
  font-weight:bold;
  text-align:right;
  content:counter(item) ".";
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.MuiTableRow-root:nth-child(even){
  background-color: rgb(240, 240, 240);
}
      
.MuiTableRow-root:nth-child(odd){
  background-color: #fff;
}

.btn-primary.disabled, .btn-primary:disabled{
  background-color: #62a1fe !important;
  border-color: #62a1fe !important;
}

.btn-danger{
  background-color: #fb233b !important;
  border-color: #fb233b !important;
}

.btn-danger:hover{
  background-color: #cf172b !important;
  border-color: #cf172b !important;
}

.btn-payment-method{
  background-color: var(--tf-color) !important;
}

.credit-card{
  background: linear-gradient(345deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%) !important;
  border: none !important;
  border-radius: 0.75rem !important;
  color: white !important;
}

.form-check-input:checked {
  background-color: #00bf66 !important;
  border-color: #00bf66 !important;
}

.unplash-logo{
  height: 24px;
}

.btn-rounded{
  border-radius: 0.5rem !important;
}

.top-1{
  color: #eac102 !important;
}

.top-2{
  color: #a9b2b8 !important;
}

.top-3{
  color: #8a3d36 !important;
}

#month-top-seller-list{
  height: 418px;
}

#month-top-seller-list::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 6px rgba(141, 141, 141, 0.3);background-color: #F5F5F5;border-radius: 1rem;}
#month-top-seller-list::-webkit-scrollbar{width: 8px;height: 0px;background-color: #F5F5F5;border-radius: 1rem;}
#month-top-seller-list::-webkit-scrollbar-thumb{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);background-color: rgb(118, 118, 118); border-radius: .5rem;}

#year-top-seller-list{
  height: 418px;
}

#year-top-seller-list::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 6px rgba(141, 141, 141, 0.3);background-color: #F5F5F5;border-radius: 1rem;}
#year-top-seller-list::-webkit-scrollbar{width: 8px;height: 0px;background-color: #F5F5F5;border-radius: 1rem;}
#year-top-seller-list::-webkit-scrollbar-thumb{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);background-color: rgb(118, 118, 118); border-radius: .5rem;}

.product-list{
  width:50%;
  max-width:50%;
  flex: 1 1 0;
}

.view-transaction{
  width:50%;
  max-width:50%;
  flex: 1 1 0;
}

.height-product-list{
  height: calc(100% - 146px) !important;
}

.options{
  width:15%;
}

.option-content-view{
  width: 85%;
}

.wrapper-product-list{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(100% / 4), 1fr));
}

.wrapper-table-list{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(100% / 6), 1fr));
}

.wrapper-without-stock-list{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(100%), 1fr));
}

.text-menu{
  display: inline-block !important;
}

.icon-menu{
  display: none !important;
}

@media screen and (max-width: 1400px) {
  .wrapper-table-list{
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 4), 1fr));
  }

  .wrapper-product-list{
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 3), 1fr));
  }
}

@media screen and (max-width: 1200px) {
  .wrapper-table-list{
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 4), 1fr));
  }

  .wrapper-product-list{
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 2), 1fr));
  }

  .text-menu{
    display: none !important;
  }
  
  .icon-menu{
    display: inline-block !important;
  }
}

@media screen and (max-width: 992px) {
  .wrapper-table-list{
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 2), 1fr));
  }

  .wrapper-product-list{
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 2), 1fr));
  }

  .text-menu{
    display: none !important;
  }
  
  .icon-menu{
    display: inline-block !important;
  }
}

@media screen and (max-width: 768px) {
  .wrapper-table-list{
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 1), 1fr));
  }

  .wrapper-product-list{
    grid-template-columns: repeat(auto-fill, minmax(calc(100% / 1), 1fr));
  }

  .text-menu{
    display: none !important;
  }
  
  .icon-menu{
    display: inline-block !important;
  }
}

@media screen and (max-width: 576px) {
  .text-menu{
    display: none !important;
  }
  
  .icon-menu{
    display: inline-block !important;
  }
}

.flex-start{
  align-content: flex-start;
}

.p-content-center{
  place-content: center;
}

.img-stock{
  width: 288px;
  height: 216px;
  background: rgb(225, 225, 225);
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%),rgb(225, 225, 225);
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}

.img-skeleton{
  width: 588px;
  height: 416px;
  background: rgb(225, 225, 225);
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%),rgb(225, 225, 225);
  background-repeat: repeat-y;
  background-size: 50px 500px;
  background-position: 0 0;
  animation: shine 1s infinite;
}

@keyframes shine {
  to {
    background-position: 100% 0, /* move highlight to right */ 0 0;
  }
}

.img-product{
  width: 100%;
  max-width: 288px;
  height: 100%;
  max-height: 216px;
  object-fit: cover;
}

.img-promotion{
  width: 100%;
  max-width: 588px;
  height: 100%;
  max-height: 416px;
  object-fit: cover;
}

.filter-origin{
  width: 110px;
}

.container-actions{
  width: 100%;
  display: inline-flex;
}

.container-actions button{
  flex: 1 100%;
  display: inline-block;
  margin: 0 .25rem;
}

.css-ig9rso-MuiToolbar-root{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.flex-1{
  flex: 1;
}

.MuiTablePagination-selectLabel{
  margin-top: 1%;
}

.MuiTablePagination-displayedRows{
  margin-top: 1%;
}

.select-product-Stock{
  width: 190px;
}

.text-test-title{
  color: #000;
  transition: color .5s ease-in-out;
  transition: font-size .5s ease-in-out;
}

.text-test-title.show{
  font-size: .875em;
  color: #6c757d;
}

.text-title-product-stock-report{
  transition: font-size .5s ease-in-out;
}

.text-title-product-stock-report.show{
  font-size: calc(1.3rem + 0.6vw);
}

.select-record-year-Stock{
  width: 120px;
}

.select-record-day-Stock{
  width: 180px;
}

.select-record-month-Stock{
  width: 150px;
}

.virtualized-window{
  background-color: #f7f7f7;
  position: relative;
  height: 200px;
  width: 100%;
  border-radius: 0.5rem;
  overflow: auto;
  will-change: transform;
  direction: ltr;
}

.box-content{
  height: calc(100% - 56px);
}

.img-expense{
  width: 623px;
  height: 100%;
}

.react-pdf__Page__canvas{
  margin: auto !important;
  width: 525px !important;
  height: 700px !important;
}

.textLayer{
  display: none !important;
}

.annotationLayer{
  display: none !important;
}

.MuiToolbar-root{
  padding-left: 0px !important;
  padding-right: 0px !important;
}


/*

<body>
  <div class="mesa">
    <div class="table"></div>
    <div class="silla-1"></div>
    <div class="silla-2"></div>
  </div>
</body>

body{
  padding: 5rem;
  display: grid;
  grid-template-columns: 2fr 2fr;
}

.mesa{
  position: relative;
  height: 100px;
  width: 300px;
}

.table{
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
  background-color: #c49952;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 5;
}

.silla-1, .silla-2{
  position: absolute;
  margin-top: -25px;
  height: 60px;
  width: 70px;
  background-color: red;
  border-radius: 15px;
  z-index: 3;
  cursor: pointer;
  transtion: .3s all;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.silla-1:hover, .silla-2:hover{
  margin-top: -20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
}

.silla-1{
  left: 50px;
}

.silla-2{
  right: 50px;
}

*/